import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

import customColours from '../../../colours';

const colors = {
  ...baseColors,
  ...customColours
}

export default colors
